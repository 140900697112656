<template>
  <div>
    <dx-util-form ref="companyInformationValidationRef"
      :form-data="companyRegistrationForm" :show-colon-after-label="true"
      label-location="top" height="560px"
    >
        <dx-util-group-item caption="Company Information" :col-count="12">
          <dx-util-item data-field="companyName" :col-span="12" editor-type="dxTextBox" :editor-options="companyNameEditorOptions">
            <dx-util-required-rule message="Company Name is required" />
            <dx-util-pattern-rule
              :pattern="companyNamePattern"
              message="Company name should be minimum 3 characters"
            />
          </dx-util-item>
          <dx-util-item v-if="companyRegistrationForm.tenantType === TenantTypeEnum.SERVING.value" data-field="tenantId" :col-span="12" editor-type="dxTextBox" :editor-options="{ inputAttr: { autocomplete: 'chrome-off'}, onValueChanged: makeUpperCase, placeholder: 'Pick a Company ID (up to 10 chars)'}">
            <dx-util-label text="Tenant ID" />
            <dx-util-required-rule message="Tenant ID is required" />
            <dx-util-pattern-rule
              :pattern="tenantPattern"
              message="Tenant should be maximum 10 capital case or digits"
            />
          </dx-util-item>
          <dx-util-item data-field="line1" :col-span="12" editor-type="dxTextBox" :editor-options="{ inputAttr: { autocomplete: 'chrome-off'}}">
            <dx-util-label text="Address Line" />
            <dx-util-required-rule message="Address details is required" />
          </dx-util-item>
          <dx-util-item data-field="city" :col-span="6" editor-type="dxTextBox" :editor-options="{ inputAttr: { autocomplete: 'chrome-off'}}">
            <dx-util-label text="City" />
            <dx-util-required-rule message="City is required" />
          </dx-util-item>
          <dx-util-item data-field="zipCode" :col-span="6" editor-type="dxTextBox" :editor-options="{ inputAttr: { autocomplete: 'chrome-off'}}">
            <dx-util-label text="Zipcode" />
            <dx-util-required-rule message="Zipcode is required" />
          </dx-util-item>
          <dx-util-item v-if="!isUSAAddress" data-field="state" :col-span="6" editor-type="dxTextBox" />
          <dx-util-item v-else data-field="state" :col-span="6" editor-type="dxSelectBox" :editor-options="stateOptions">
            <dx-util-label text="State" />
            <dx-util-required-rule message="State is required" />
          </dx-util-item>
          <dx-util-item data-field="country" :col-span="6" editor-type="dxSelectBox" :editor-options="countryOptions">
            <dx-util-label text="Country" />
            <dx-util-required-rule message="Country is required" />
          </dx-util-item>
          <dx-util-item :editor-options="phoneEditorOptions" data-field="phoneNumber"
            :col-span="6" editor-type="dxTextBox"
          >
            <dx-util-label text="Phone Number" />
            <dx-util-pattern-rule
                :pattern="phonePattern"
                message="The phone must have a correct USA phone format"
              />
            <dx-util-required-rule message="Phone number is required" />
          </dx-util-item>
          <dx-util-item data-field="faxNumber" :editor-options="phoneEditorOptions" :col-span="6" editor-type="dxTextBox">
            <dx-util-label text="Fax Number" />
          </dx-util-item>
          <dx-util-item data-field="warehouseName" :col-span="12" editor-type="dxTextBox" :editor-options="{ inputAttr: { autocomplete: 'chrome-off'}}">
            <dx-util-label text="Warehouse Name" />
            <dx-util-pattern-rule :pattern="warehouseNamePattern" message="Warehouse name should minimum 4 maximum 20 letters" />
          </dx-util-item>
        </dx-util-group-item>
      </dx-util-form>
  </div>
</template>

<script>
import {
  computed, // watchEffect, // computed, watch, computed, reactive, onMounted, ref, watchEffect,
} from '@vue/composition-api'
import { countriesDetail, states } from '@/http/requests/statics/locations'
import { useCustomerRegistration } from '../useCustomerRegistration'

export default {
  props: {
    checkForm: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { companyRegistrationForm, TenantTypeEnum } = useCustomerRegistration()

    const warehouseNamePattern = /^[a-zA-Z0-9_ ]{0,20}$/
    const companyNamePattern = /^.{3,}$/
    const tenantPattern = /^[A-Z0-9]{1,10}$/
    const phonePattern = /^[02-9]\d{9}$/

    const phoneEditorOptions = computed(() => ({
      mask: '+1 (000) 000-0000',
      maskInvalidMessage: 'The phone must have a correct USA phone format',
    }))

    const isUSAAddress = computed(() => companyRegistrationForm.country === 'USA')

    const stateOptions = computed(() => ({
      autoComplete: 'none',
      searchEnabled: true,
      items: states,
      acceptCustomValue: false,
      inputAttr: {
        autocomplete: 'chrome-off',
      },
    }))

    const countryOptions = computed(() => ({
      autoComplete: 'none',
      searchEnabled: true,
      items: countriesDetail,
      valueExpr: 'code',
      displayExpr: 'name',
      inputAttr: {
        autocomplete: 'chrome-off',
      },
    }))

    function makeUpperCase() {
      companyRegistrationForm.tenantId = companyRegistrationForm.tenantId.toUpperCase()
    }

    const companyNameEditorOptions = computed(() => ({
      inputAttr: { autocomplete: 'chrome-off' },
      onValueChanged: e => {
        companyRegistrationForm.warehouseName = e.value
      },
    }))

    return {
      companyRegistrationForm,
      TenantTypeEnum,
      warehouseNamePattern,
      companyNamePattern,
      tenantPattern,
      phonePattern,
      isUSAAddress,
      phoneEditorOptions,
      stateOptions,
      countryOptions,
      makeUpperCase,
      companyNameEditorOptions,
    }
  },
  watch: {
    checkForm(currentValue) {
      if (currentValue) {
        const validationResult = this.$refs.companyInformationValidationRef.instance.validate()
        this.$emit('emit-validation-result', validationResult)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
